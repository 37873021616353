.header-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header-mobile {
    width: 100%;
    height: 80px;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }

  .header-mobile > img {
    position: absolute;
    right: 0;
    margin-right: 7%;
    width: 30px;
  }

  .header-mobile > h2 {
    font-family: "Oswald-Light";
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    margin: 1rem 0%;
  }

  .header-mobile > a {
    position: absolute;
    left: 7%;
  }

  .header-mobile .account-icon {
    float: left;
  }

  .header-mobile > .go-account-arrow > img {
    width: 15px;
  }
}
