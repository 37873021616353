.connect-container {
  min-height: calc(100vh - 400px);
}

.connect-container > .title {
  width: 100%;
  display: flex;
  align-items: center;
}

.connected > .disconnect-button {
  margin: 25px 0px;
  height: 50px;
  width: 100%;
  color: #bf56e5;
  background-color: #1a1a1c;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald-Regular";
  font-size: 18px;
}

@media (min-width: 1025px) {
  .connected > .disconnect-button {
    display: none;
  }
  .connect-container {
    width: 100%;
    padding: 30px 15% 90px 15%;
  }
  .connect-container > .title {
    height: 200px;
    justify-content: space-between;
  }
  .connect-container > h2 {
    font-family: "Oswald-Light";
    font-size: 20px;
    color: #bf56e5;
  }
  .connect-container > span {
    display: none;
  }
  .connect-container > .my-account,
  .connect-container > .not-connected {
    margin: 25px 0px;
    height: 70px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-family: "Oswald-Regular";
    font-size: 18px;
    margin-bottom: 80px;
  }
  .connect-container > .my-account > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .connect-container > .my-account {
    background-color: #3a3a3a;
    color: #ffffff;
    justify-content: flex-end;
  }
  .connect-container > .not-connected {
    background-color: #bf56e5;
    color: #1a1a1c;
    justify-content: center;
    cursor: pointer;
  }

  .connect-container > .my-account > div > img {
    width: 40px;
  }
  .connect-container > .my-account > img {
    width: 18px;
    margin-right: 20px;
  }
  .connect-container > .not-connected > img {
    width: 30px;
  }
  .connect-container > .my-account > div > div,
  .connect-container > .not-connected > div {
    margin-left: 15px;
  }

  .connect-container > .connected > .wallet-info {
    margin: 40px 0px 70px 0px;
    padding: 0px 20px;
    height: 90px;
    width: 100%;
    background-color: #bf56e5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Oswald-Regular";
    font-size: 18px;
    color: #1a1a1c;
  }

  .connect-container > .connected > .wallet-info > .user-info {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .connect-container > .connected > .wallet-info > .user-info > div {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.219);
    border-radius: 10px;
    font-size: 30px;
  }

  .connect-container > .connected > .wallet-info > .user-info > h2 {
    font-size: 18px;
    margin: 0px;
    margin-left: 20px;
  }
  .connect-container > .connected > .wallet-info > .user-info > span {
    font-size: 20px;
    color: black;
    margin-left: 20px;
  }

  .connect-container > .title > .go-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    font-family: "Spantaran";
    font-size: 20px;
    color: #d5b54b;
    cursor: pointer;
  }

  .connect-container > .title > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
  }

  .connect-container > .title > div > img {
    width: 50px;
  }

  .connect-container > .title > div > h2 {
    font-family: "Oswald-Light";
    font-size: 20px;
    color: #ffffff;
    margin: 0px;
  }
  .address-mobile {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .address-desktop {
    display: none;
  }
  .connect-container {
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    padding: 30px 20px 0px 20px;
  }
  .connect-container > .title {
    height: 30px;
    justify-content: center;
    margin-bottom: 30px;
  }
  .connect-container > .w-100-percent {
    display: none;
  }
  .connect-container > h2,
  .connect-container > span {
    font-family: "Oswald-Light";
    font-size: 20px;
    color: #bf56e5;
  }
  .connect-container > .my-account {
    display: none;
  }
  .connect-container > .not-connected {
    margin: 25px 0px;
    height: 50px;
    width: 100%;
    background-color: #bf56e5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Oswald-Regular";
    font-size: 18px;
    color: #1a1a1c;
  }
  .connect-container > .not-connected > div {
    margin-left: 15px;
  }

  .connect-container > .connected > .wallet-info {
    margin: 25px 0px;
    padding: 0px 15px;
    height: 90px;
    width: 100%;
    background-color: #bf56e5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Oswald-Regular";
    font-size: 18px;
    color: #1a1a1c;
  }

  .connect-container > .connected > .wallet-info > .user-info {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .connect-container > .connected > .wallet-info > .user-info > div {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.219);
    border-radius: 10px;
    font-size: 30px;
  }

  .connect-container > .connected > .wallet-info > .user-info > h2 {
    font-size: 15px;
    margin: 0px;
    margin-left: 5px;
  }
  .connect-container > .connected > .wallet-info > .user-info > span {
    font-size: 15px;
    color: black;
    margin-left: 5px;
  }

  .connect-container > .title > .go-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 185px;
    font-family: "Spantaran";
    font-size: 15px;
    color: #d5b54b;
    cursor: pointer;
  }

  .connect-container > .title > div {
    display: none;
  }
}
