* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Reset All default styles */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

ol,
ul,
dl {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

/* Bootstrap default input focus style */
input[type="text"]:focus {
  box-shadow: none;
  outline: 0 none;
}

#root {
  background: rgb(39, 39, 39);
  background: linear-gradient(
    90deg,
    rgba(39, 39, 39, 1) 30%,
    rgba(39, 39, 39, 0.8939950980392157) 50%,
    rgba(39, 39, 39, 1) 70%
  );
}

.cursor-pointer {
  cursor: pointer;
}
.padding-8px-sides {
  padding: 0px 8px;
}
.margin-topbot-negative-100 {
  margin: -100px 0px;
}

.pointer-events-none {
  pointer-events: none;
}
/* Fonts */

@font-face {
  font-family: "Oswald-ExtraLight";
  src: local("Oswald-ExtraLight"),
    url(./assets/fonts/Oswald-ExtraLight.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Oswald-Light";
  src: local("Oswald-Light"),
    url(./assets/fonts/Oswald-Light.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Oswald-Regular";
  src: local("Oswald-Regular"),
    url(./assets/fonts/Oswald-Regular.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Oswald-Medium";
  src: local("Oswald-Medium"),
    url(./assets/fonts/Oswald-Medium.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Oswald-SemiBold";
  src: local("Oswald-SemiBold"),
    url(./assets/fonts/Oswald-SemiBold.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(./assets/fonts/Montserrat-Regular.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./assets/fonts/Montserrat-Medium.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
    url(./assets/fonts/Montserrat-Light.ttf) format("TrueType");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: local("Montserrat-ExtraLight"),
    url(./assets/fonts/Montserrat-ExtraLight.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat-Thin"),
    url(./assets/fonts/Montserrat-Thin.ttf) format("TrueType");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(./assets/fonts/Montserrat-SemiBold.ttf) format("TrueType");
  font-display: swap;
}
@font-face {
  font-family: "MontserratAlternates-ExtraBold";
  src: local("MontserratAlternates-ExtraBold"),
    url(./assets/fonts/MontserratAlternates-ExtraBold.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates-Italic";
  src: local("MontserratAlternates-Italic"),
    url(./assets/fonts/MontserratAlternates-Italic.ttf) format("TrueType");
  font-display: swap;
}

@font-face {
  font-family: "Cyberway-Riders";
  src: local("Cyberway-Riders"),
    url(./assets/fonts/Cyberway-Riders.otf) format("OpenType");
  font-display: swap;
}

@font-face {
  font-family: "Spantaran";
  src: local("Spantaran"), url(./assets/fonts/Spantaran.ttf) format("TrueType");
  font-display: swap;
}
