.main-container .i-mail {
  width: 240px;
  height: 43px;
  background-color: #000000;
  border: 1px solid #d5b54b;
  border-radius: 3px;
  outline: none;
  color: #ffffff;
  padding: 1rem;
}

.main-container .i-mail::placeholder {
  color: #878787 !important;
  font-size: 21px;
  padding-left: 1rem;
}

.main-container .top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.main-container .bottom-container {
  width: 100%;
}

.main-container .f-title {
  color: #ffffff;
  font-family: "Oswald-Medium";
  font-size: 20px;
}

.main-container .klaviyo-form-Y2NKa4 {
  margin-bottom: 2rem;
}

.main-container
  .klaviyo-form-Y2NKa4
  > div
  > form
  > div
  > div
  > div
  > div
  > input[type="email"] {
  border-radius: 5px !important;
  height: 50px !important;
}

.main-container
  .klaviyo-form-Y2NKa4
  > div
  > form
  > div
  > div
  > div
  > div
  > input[type="email"]::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: "Oswald-Regular" !important;
}

.main-container
  .klaviyo-form-Y2NKa4
  > div
  > form
  > div
  > div:nth-child(2)
  > div
  > button {
  background-color: #d5b54b !important;
  color: #000000 !important;
  -webkit-clip-path: polygon(
    0% 30%,
    5% 0%,
    100% 0%,
    100% 70%,
    95% 100%,
    0% 100%
  );
  clip-path: polygon(0% 30%, 5% 0%, 100% 0%, 100% 70%, 95% 100%, 0% 100%);
  font-family: "Oswald-Medium" !important;
  font-size: 21px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  padding: 15px 0 !important;
}

.main-container .subscribe-container {
  width: 240px;
  margin: 2rem auto;
  position: relative;
}

.main-container .subscribe-container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  font-size: 21px;
  font-family: "Oswald-Medium";
}

.main-container ul > li {
  padding-bottom: 1rem;
}

.main-container .payment {
  align-items: center;
  justify-content: space-evenly;
}

.bottom-container,
.main-container .bottom-container .privacy-terms {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #878787;
}

.main-container .bottom-container .privacy-terms {
  text-decoration: underline;
}

.main-container div > .social-links > .social-icon {
  color: #ffffff;
}

.footer-link {
  color: #878787;
}

.footer-link:hover {
  color: #ffffff;
}

.main-container > .top-container > .text-left > ul > li > img {
  width: 85px;
}

/*
    Media querys:
    ----------------
    0px — 480px
    481px — 768px
    769px — 1024px
    1025px — 1200px
    1201px — 1366px
    1367px — 1440px
    1440px — 1920px
    1920px — max
*/

@media (min-width: 1025px) {
  .main-container {
    background-color: #000000;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #878787;
    font-family: "Oswald-Regular";
  }
}

@media screen and (max-width: 1024px) {
  .main-container {
    display: none;
  }
}
