.menu-container {
  width: 100%;
  height: 92px;
  background: #000000;
  margin-bottom: 1.5rem;
}

.menu-list {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-list li:not(:nth-child(1)) {
  padding: 0 2%;
}

.menu-list li > a {
  color: #ffffff;
  font-family: "Spantaran";
  font-size: 20px;
  height: 100%;
}

.menu-button .input-group-text,
.menu-button .form-control {
  border: none;
  outline: none;
}

.menu-button .form-control::placeholder {
  color: #878787 !important;
  font-family: "Montserrat-Medium";
}

.menu-icon {
  padding-left: 2%;
}

.menu-button a:hover {
  color: #d5b54b !important;
  border-bottom: 1px solid #d5b54b;
  transition: color 0.5s, border-bottom 0.5s ease-out;
}
.menu-button-violet a:hover {
  color: #bf56e5 !important;
  border-bottom: 1px solid #bf56e5;
  transition: color 0.5s, border-bottom 0.5s ease-out;
}
.active a {
  color: #d5b54b !important;
  border-bottom: 1px solid #d5b54b;
  transition: color 0.5s, border-bottom 0.5s ease-out;
}
.active-violet a {
  color: #bf56e5 !important;
  border-bottom: 1px solid #bf56e5;
  transition: color 0.5s, border-bottom 0.5s ease-out;
}
.menu-button > button {
  background: transparent;
  border: none;
}
.connect-button {
  font-family: "Spantaran";
  font-size: 20px;
  background: none;
  color: #ffffff;
  border: none;
}
.connect-button:hover {
  color: #bf56e5 !important;
  border-bottom: 1px solid #bf56e5;
  transition: color 0.5s, border-bottom 0.5s ease-out;
}
.wallet-address {
  font-family: "Oswald-Regular";
  color: #ffffff;
  font-size: 20px;
}

/*
    Media querys:
    ----------------
    0px — 480px
    481px — 768px
    769px — 1024px
    1025px — 1200px
    1201px — 1366px
    1367px — 1440px
    1440px — 1920px
    1920px — max
*/
/* @media screen and (max-width: 1650px) {

} */

@media screen and (max-width: 1440px) {
  .menu-list li:not(:nth-child(1)) {
    padding: 0 1.5%;
  }

  .menu-list li:last-child {
    margin-right: 1%;
  }
}

@media screen and (max-width: 1366px) {
  .menu-list li > a {
    font-size: 18px;
  }

  .menu-list li:not(:nth-child(1)) {
    padding: 0 1.5%;
  }

  .menu-list li:last-child {
    margin-right: 1%;
  }
}

@media screen and (max-width: 1200px) {
  .menu-list li > a {
    font-size: 16px;
  }

  .menu-list li:not(:nth-child(1)) {
    padding: 0 1%;
  }

  .menu-list li:last-child {
    margin-right: 1%;
  }
}

@media screen and (max-width: 1024px) {
  .menu-container {
    display: none;
  }
}
