.line-side-container {
  max-width: 1238px;
  height: 150px;
  margin: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.line-side-container .text-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-description-container .description-right,
.text-description-container .text-right,
.text-description-container .description-left,
.text-description-container .text-left {
  position: relative;
  width: 100%;
  height: 50%;
  color: #ffffff;
}

.text-description-container .text-right span,
.text-description-container .text-left span {
  position: absolute;
  bottom: 10px;
  font-family: "Spantaran";
  font-size: 40px;
}

.text-description-container .description-right span,
.text-description-container .description-left span {
  position: absolute;
  bottom: 0;
  font-family: "Oswald-light";
  font-size: 22px;
}

/*
    Media querys:
    ----------------
    0px — 480px
    481px — 768px
    769px — 1024px
    1025px — 1200px
    1201px — 1366px
    1367px — 1440px
    1440px — 1920px
    1920px — max
*/

@media screen and (min-width: 1367px) and (max-width: 1440px) {
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .line-side-container {
    max-width: 1000px;
  }

  .text-description-container .text-right span,
  .text-description-container .text-left span {
    font-size: 35px;
  }
}
