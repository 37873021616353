@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

.loaderImg {
    animation: blink 1.5s;
    animation-iteration-count: infinite;
}
