.bc-dark-grey {
  background-color: #131313 !important;
}

.m-left-auto {
  margin-left: auto;
}

.m-bottom-20px {
  margin-left: 20px;
}

.w-500 {
  width: 500px;
}

.w-250 {
  width: 250px;
}

.h-65px {
  height: 65px !important;
}

.w-100-percent {
  width: 100%;
}

.font-spantaran {
  font-family: "Spantaran";
}

.m-left-5-percent {
  margin-left: 5%;
}

.right-20 {
  right: 20px;
}

.left-zero {
  left: 0;
}

.top-zero {
  top: 0;
}

.bottom-zero {
  bottom: 0;
}

.absolute {
  position: absolute;
}

.mt-zero {
  margin-top: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}
