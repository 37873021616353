.wishlist-container {
  width: 100%;
  height: 100%;
}

.wishlist-container .wishlist-content-box {
  width: 100%;
  background: url("../images/utils/background_neon_net_stars.svg") center top;
  background-repeat: repeat-y;
  background-size: 91.8%;
  min-height: calc(100vh - 500px);
}

.wishlist-container .wishlist-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
}

.wishlist-title > h1 {
  font-family: "Cyberway-Riders";
  color: #bf56e5;
  text-shadow: 0px 0px 15px rgba(191, 86, 229, 0.8);
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  word-spacing: 30px;
  margin-bottom: 3%;
}

.wishlist-container .wishlist-items-container {
  width: 100%;
}

.wishlist-items-container .w-item-box {
  width: 100%;
}

.wishlist-items-container .w-item-box > div {
  width: 100%;
  height: 100%;
}

.wishlist-items-container .w-item-box .w-item-list {
  width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wishlist-items-container div.no-items {
  width: 100%;
  height: 300px;
}

.wishlist-items-container .no-items > h2 {
  text-align: center;
  font-family: "Spantaran";
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
}

.wishlist-container .love-it-container {
  width: 100%;
  background: url("../images/utils/neon_stars.svg") no-repeat center top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wishlist-container .love-it-container > h2 {
  font-family: "Spantaran";
  font-size: 30px;
  color: #d5b54b;
}

.wishlist-container .love-it-container > p {
  font-family: "Montserrat-Regular";
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

.wishlist-container .love-it-container > div {
  margin-bottom: 5rem;
}

/*
    Media querys:
    ----------------
    0px — 480px
    481px — 768px
    769px — 1024px
    1025px — 1200px
    1201px — 1366px
    1367px — 1440px
    1441px — 1920px
    1920px — max
*/

@media screen and (max-width: 1024px) {
  .wishlist-container {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
    padding-top: 2rem;
  }

  .wishlist-container .wishlist-content-box {
    width: 100%;
    background: none;
  }

  .wishlist-container
    .wishlist-items-container
    .w-item-box
    > div
    > .line-side-container {
    display: none;
  }

  .wishlist-container .wishlist-title {
    display: none;
  }

  .wishlist-container .wishlist-items-container .w-item-box .w-item-list {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 480px) {
  .wishlist-container .wishlist-items-container .w-item-box .w-item-list {
    padding: 0 1rem;
  }

  .wishlist-container .love-it-container > h2 {
    text-align: center;
  }

  .wishlist-container .love-it-container > p {
    font-size: 15px;
    margin-bottom: 0 !important;
  }
}
