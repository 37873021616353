.wishlist-item-box {
  background: #000000;
  box-shadow: 0px 0px 10px rgba(191, 86, 229, 0.5);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
}

.wishlist-item-box .item-box-image {
  min-width: 111px;
  min-height: 111px;
  width: 111px;
  height: 111px;
  border-radius: 10px;
  overflow: hidden;
}

.wishlist-item-box .item-box-image > img {
  width: 100%;
  height: 100%;
}

.wishlist-item-box .children-container {
  width: 100%;
  max-width: 950px;
  height: 111px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: right;
}

.wishlist-item-box .children-container .item-attr {
  display: flex;
  flex-direction: column;
}

.wishlist-item-box .children-container .item-attr > span:nth-child(1) {
  font-family: "Oswald-Medium";
  font-size: 27px;
  color: #bf56e5;
}

.wishlist-item-box .children-container .item-attr > span:nth-child(2) {
  font-family: "Montserrat-Light";
  font-size: 14px;
  color: #ffffff;
}

.wishlist-item-box .item-box-prices {
  display: flex;
  justify-content: space-between;
}

.wishlist-item-box .item-box-prices > span:nth-child(1) {
  font-family: "Oswald-SemiBold";
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
}

.wishlist-item-box .item-box-prices > span:nth-child(2) {
  font-family: "Oswald-SemiBold";
  font-size: 24px;
  color: #ffffff;
}

.wishlist-item-box .item-box-prices > button {
  background-color: transparent;
  border: none;
}

@media screen and (max-width: 768px) {
  .wishlist-item-box .item-box-image {
    min-height: 75px;
    min-width: 75px;
    width: 75px;
    height: 75px;
    border-radius: 10px;
    overflow: hidden;
  }

  .wishlist-item-box {
    padding: 0 1.5rem;
  }

  .wishlist-item-box .children-container .item-attr > span:nth-child(1) {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .wishlist-item-box .item-box-image {
    margin-right: 15px !important;
  }
}
