.exchange-slider,
.infinite-carousel,
.InfiniteCarouselFrame {
  height: 30px;
  box-sizing: border-box;
}

.slide {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide .currency {
  font-weight: bold;
}

.marquee {
  width: 100%;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  color: #ffffff;
}

.marquee > div > span > span {
  font-weight: bold;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/*
    Media querys:
    ----------------
    0px — 480px
    481px — 768px
    769px — 1024px
    1025px — 1200px
    1201px — 1366px
    1367px — 1440px
    1441px — 1920px
    1920px — max
*/

@media (min-width: 1025px) {
  .marquee > div > span {
    margin: 0px 300px 0px 0px;
  }
  .marquee div {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 150s linear infinite;
  }
  .exchange-container {
    width: 100%;
    height: 30px;
    background-color: #bf56e5;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .exchange-container {
    width: 100%;
    height: 30px;
    background-color: #bf56e5;
  }
  .marquee > div > span {
    margin: 0px 50px 0px 0px;
  }
  .marquee div {
    display: inline-block;
    padding-left: 50%;
    animation: marquee 150s linear infinite;
  }
}
